import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalTrigger,
} from '@/components/common/Modal';
import { Title } from '@/components/common/Title';
import { PortableText } from '@/components/common/utils';
import { WistiaEmbed } from '@/components/common/WistiaEmbed';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { blockUrlWithAudience } from '@/lib/utils';
import { subheadingMedium } from '@/styles/typography';

import type { IconButtonProps } from '@/components/common/Buttons';
import type { SanityCreativeHeroType } from '@/types/sanity';
import type { BlockWithAudience, CommonCTAProps } from '@/types/shared';

type VideoCTA = {
  _type: 'videoCTA';
  wistiaPopover?: {
    _type: 'wistiaPopover';
    videoId: string;
  };
} & Pick<CommonCTAProps, 'text' | 'trackingTag' | 'accessibleLabel'>;

export interface CreativeHeroProps
  extends Omit<SanityCreativeHeroType, 'ctaList'>,
    BlockWithAudience {
  ctaList: (CommonCTAProps | VideoCTA)[];
}

export const CreativeHero = ({
  heading,
  subheading,
  ctaList,
  pageAudience,
  isAudienceSwitcherEnabled,
  blockSpacing,
  paddingAdjustment,
}: CreativeHeroProps) => {
  const [primaryCTA, secondaryCTA] = ctaList ?? [];

  return (
    <Section
      backgroundColour="--background-subtle"
      className="illustrated-hero"
      spacing={blockSpacing}
      verticalPadding={paddingAdjustment}
    >
      <Row>
        <Col sizes={{ base: 4, md: 10 }} start={{ md: 2 }}>
          <HeroWrapper>
            <TextWrapper>
              <Title
                size="xl"
                variant="hero"
                heading={heading}
                desktopAlignment="center"
                mobileAlignment="start"
              />
              {!!subheading && (
                <SubheadingWrapper>
                  <PortableText value={subheading} />
                </SubheadingWrapper>
              )}
            </TextWrapper>

            {ctaList?.length > 0 && (
              <ButtonWrapper>
                {secondaryCTA && (
                  <CTAButton
                    variant="flat-light"
                    cta={secondaryCTA}
                    isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                    pageAudience={pageAudience}
                  />
                )}
                {primaryCTA && (
                  <CTAButton
                    variant="solid-light"
                    cta={primaryCTA}
                    icon="ArrowRight"
                    hideIconOnMobile
                    isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                    pageAudience={pageAudience}
                  />
                )}
              </ButtonWrapper>
            )}
          </HeroWrapper>
        </Col>
      </Row>
    </Section>
  );
};

interface CTAButtonProps extends BlockWithAudience {
  cta: CommonCTAProps | VideoCTA;
  variant: IconButtonProps['variant'];
  icon?: IconButtonProps['icon'];
  hideIconOnMobile?: boolean;
}

const CTAButton = ({
  cta,
  pageAudience,
  isAudienceSwitcherEnabled,
  variant,
  icon,
  hideIconOnMobile,
}: CTAButtonProps) => {
  const commonProps = {
    size: 'lg',
    variant: variant,
    icon: icon,
    hideIconOnMobile: hideIconOnMobile,
    'aria-label': cta?.accessibleLabel,
    className: cta.trackingTag
      ? `tracking-${cta.trackingTag.blockName}__${cta.trackingTag.blockAction}`
      : '',
  } as const;

  if (cta._type === 'CTA') {
    return (
      <Button
        {...commonProps}
        href={blockUrlWithAudience(
          cta.url,
          cta.audienceRef,
          pageAudience,
          cta.noAudienceToggle,
          isAudienceSwitcherEnabled,
        )}
      >
        {cta.text}
      </Button>
    );
  } else if (cta._type === 'videoCTA') {
    return (
      <Modal>
        <ModalTrigger asChild>
          <Button {...commonProps}>{cta.text}</Button>
        </ModalTrigger>
        <ModalContent className="Modal">
          <ModalClose />

          <WistiaEmbed videoId={cta?.wistiaPopover?.videoId} />
        </ModalContent>
      </Modal>
    );
  }
};

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubheadingWrapper = styled.div`
  padding-top: var(--space-component-xxl);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    ${subheadingMedium}
    color: var(--text-warm-base);
    z-index: 1;

    strong {
      font-weight: var(--font-weight-medium);
    }
  }

  ${screen.sm} {
    align-items: center;
    text-align: center;
    ::before {
      content: url(/images/left-brand-strips.svg);
      position: absolute;
      top: 55%;
      transform: translateY(-50%);
      left: -800px;
    }

    ::after {
      content: url(/images/right-brand-strips.svg);
      align-content: center;
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      right: -800px;
    }
  }

  ${screen.md} {
    ::before {
      left: -725px;
    }

    ::after {
      right: -725px;
    }
  }

  ${screen.lg} {
    ::before {
      left: -725px;
    }

    ::after {
      right: -725px;
    }
  }

  ${screen.xl} {
    ::before {
      left: calc((-100vw + 150px) / 2);
    }

    ::after {
      right: calc((-100vw + 150px) / 2);
    }
  }

  @media (min-width: 1800px) {
    ::before {
      left: calc((-100vw + 400px) / 2);
    }

    ::after {
      right: calc((-100vw + 400px) / 2);
    }
  }

  @media (min-width: 2100px) {
    ::before {
      left: calc((-100vw + 700px) / 2);
    }

    ::after {
      right: calc((-100vw + 700px) / 2);
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: var(--space-component-lg);
  padding-top: var(--space-block-lg);
  flex-direction: column;
  align-items: center;
  width: 100%;

  a,
  button {
    width: 100%;
    justify-content: center;
  }

  ${screen.sm} {
    flex-direction: row;

    width: initial;

    a,
    button {
      width: fit-content;
    }
  }
`;
